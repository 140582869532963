import { IBannerItem } from '../interfaces/shop';

export const BANNERS = [
  {
    type: 'personalised',
    link: '/consultation',
    excludedShopCategories: [
      { category: 'dna & blood tests', bannerTypeToUse: 'nutritionBundles' },
      { category: 'health bundles', bannerTypeToUse: 'nutritionBundles' },
    ],
    isDefault: true,
  },
  {
    type: 'boxingDay',
    link: '/consultation',
  },
  {
    type: 'nutritionBundles',
    link: '/products/bundles',
  },
  {
    type: 'valentinesDay',
    link: '/product/omega-3',
  },
  {
    type: 'vitcCollagen',
    link: '/product/collagen-vit-c-effervescent',
    excludedShopCategories: [{ category: 'vegan', bannerTypeToUse: 'personalised' }],
    isDefault: false,
  },
  {
    type: 'dailyBiotic',
    link: '/product/daily-biotic',
    excludedShopCategories: [{ category: 'vegan', bannerTypeToUse: 'personalised' }],
    isDefault: false,
  },
  {
    type: 'blackFriday',
    isDefault: false,
  },
  {
    type: 'xMas023',
    isDefault: false,
    link: '/products/bundles',
  },
  {
    type: 'boxingDay',
    isDefault: false,
  },
] as IBannerItem[];
